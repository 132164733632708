const noticeConfirmModals = () => {
    return /* html */`
        <div id="modal-confirm" class="modal" tabindex="-1" role="dialog" style="z-index: 1072;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirmation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div id="confirm-msg" style="margin-bottom: 1rem;"></div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button id="button-confirm" type="button" class="btn btn-primary" onclickm="return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-notice" class="modal" tabindex="-1" role="dialog" style="z-index: 1072;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Notice</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div id="notice-msg" style="margin-bottom: 1rem"></div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-notice" type="button" class="btn btn-primary" onclickm="return false;">Close</button>
                        <button id="button-proceed-anyway" style="display:none" type="button" class="btn btn-primary" onclickm="return false;">Proceed Anyway</button>
                    </div>
                </div>
            </div>
        </div>
    `;
};
const preAppModals = () => {
    return /* html */`
        <div id="modal-logged-out" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Session Timed Out</h5>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div id="modal-logged-out-msg" class="modal-body">
                        Your session has timed out, please sign-in again.
                    </div>
                    <div class="modal-footer">
                        <button id="button-logged-out" type="button" class="btn btn-primary" onclickm="authManager.logout();return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-loading-libmgr" class="modal" tabindex="-1" role="dialog" style="z-index: 1074;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Library Manager</h5>
                    </div>
                    <div class="modal-body">
                        <div id="libmgr-loader">
                            <div id="loading-libmgr-text" style="display:flex;justify-content:center;margin-bottom:5px"></div>
                            <div id="loading-libmgr-pending" style="font-size:small;display:flex;justify-content:center;margin-bottom:10px"></div>
                            <div class="progress progress-normal">
                                <div
                                    class="loading-libmgr-progress progress-bar bg-init"
                                    role="progressbar"
                                    style="width: 5%;transition:none !important;"
                                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-loading-libmgr-cancel" disabled type="submit" class="btn btn-primary" onclickm="cancelDownloadBackgroundLibs();return false;">Cancel</button>
                        <button id="button-loading-libmgr-retry" disabled type="submit" class="btn btn-primary" onclickm="retryDownloadBackgroundLibs(true);return false;">Retry</button>
                        <button id="button-loading-libmgr-ok" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-bbwallet-login" class="modal" tabindex="-1" role="dialog" style="z-index: 1074;">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">BB Wallet Password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div id="bbwallet-login-msg">
                        Please enter the password that will be used for BB Wallet signing requests. If you later realise
                        an incorrect password was used, tap Link BB Wallet again and enter the correct password.
                        </div>
                        <div style="display: flex;justify-content: center;margin-top: 10px;">
                            <div class="form-floating">
                                <input class="form-control" type="password" id="bbwallet-login-password"></input>
                                <label for="bbwallet-login-password">Password</label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button id="button-bbwallet-login-ok" data-bb-listener-runtime-only type="button" class="btn btn-primary" onclickm="return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-bbwallet-confirm" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1074;">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">BB Wallet Confirmation</h5>
                        <button style="display:none" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div id="bbwallet-confirm-msg"></div>
                        <div id="bbwallet-confirm-password-confirm-wrapper">
                            <div style="display: flex;justify-content: center;margin-top: 10px;">
                                <div class="form-floating">
                                    <input class="form-control" type="password" id="bbwallet-confirm-password"></input>
                                    <label for="bbwallet-confirm-password">Password</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-bbwallet-confirm-cancel" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button id="button-bbwallet-confirm-ok" data-bb-listener-runtime-only type="button" class="btn btn-primary" onclickm="return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-logged-out-sign-in" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="modal-logged-out-sign-in-title" class="modal-title">Session Timed Out</h5>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div id="modal-logged-out-sign-in-msg" class="modal-body">
                        Your session has timed out, please sign-in again. Otherwise, sign-out to end your session.
                    </div>
                    <div
                        style="text-align: center;margin: 10px;display: none;"
                        id="resignin-msg"
                    >
                    </div>
                    <div id="resignin-cognito-section">
                        <div
                            style="display:flex; justify-content: center;margin-bottom: 20px;"
                        >
                            <div
                                class="div-row-v-md"
                                style="width: 280px;"
                            >
                                <form
                                    class="div-row-v-md"
                                >
                                    <div
                                        class="form-floating" style="flex:1"
                                    >
                                        <input
                                            id="resignin-username"
                                            name="username"
                                            type="text"
                                            class="form-control"
                                            autocomplete="off"
                                            required
                                            readonly
                                        ></input>
                                        <label id="label-username" for="resignin-username">Username or Email</label>
                                    </div>
                                    <div
                                        class="form-floating"
                                    >
                                        <input
                                            id="resignin-password"
                                            type="password"
                                            name="password"
                                            class="form-control"
                                            required
                                            autocomplete="off"
                                        ></input>
                                        <label for="resignin-password">Password</label>
                                        <div
                                            id="button-resignin-show-hide-password"
                                            style="position: absolute;top: calc(50% - 12px);right: 8%;cursor: pointer;"
                                                onclickm="bobbobAuth.toggleShowHidePassword('#icon-resignin-show-password', '#resignin-password');">
                                            <i id="icon-resignin-show-password" style="height:24px" class="far fa-eye-slash"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-mm-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-mm-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-mm-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect Metamask"
                                    onclickm="bobbobAuth.connectMetamask(event, '#resignin-mm-button', true, false);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>MetaMask</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-ledger-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-ledger-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-ledger-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect Ledger"
                                    onclickm="bobbobAuth.checkDownloadLedgerConnect(event, '#resignin-ledger-button', true, false);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>Ledger</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-bb-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-bb-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-bb-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect BB Wallet"
                                    onclickm="bobbobBbwalletProxy.connectBbwallet(event, '#resignin-bb-button', false, true);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>BB Wallet (Remote)</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-bbl-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-bbl-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-bbl-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect BB Wallet"
                                    onclickm="bobbobBbwalletProxy.connectBbwallet(event, '#resignin-bbl-button', true, true);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>BB Wallet</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-logged-out-sign-in-ok" type="button" class="btn btn-primary" onclickm="bobbobAuth.cognitoSignin(event, this, true);return false;">Sign-in</button>
                        <button id="button-logged-out-sign-in-cancel" type="button" class="btn btn-primary" onclickm="authManager.logout();return false;">Sign-out</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-multi-sign-in" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Multiple Sign-in Detected</h5>
                    </div>
                    <div class="modal-body">
                        You have signed in from another browser instance, press OK to refresh.
                    </div>
                    <div class="modal-footer">
                        <button id="button-multi-sign-in" type="button" class="btn btn-primary" onclickm="preAppReload();window.location.href = '';return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-loading-lib" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="loading-lib-title" class="modal-title">Gnosis Loader</h5>
                    </div>
                    <div class="modal-body">
                        <div id="lib-loader">
                            <div id="loading-lib-text" style="display:flex;justify-content:center;margin-bottom:10px"></div>
                            <div class="progress progress-normal">
                                <div id="loading-lib-progress" class="progress-bar bg-warning" role="progressbar" style="width: 5%;transition:none !important;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-loading-lib-retry" disabled type="submit" class="btn btn-primary" onclickm="checkDownloadGnosis();return false;">Retry</button>
                        <button id="button-loading-lib-ok" disabled type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    `;
};

module.exports = {
    noticeConfirmModals,
    preAppModals,
};
