/* eslint-disable no-console */
// require('navigator.locks');
const { DateTime } = require('luxon');
// const { CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } = require('amazon-cognito-identity-js');
const ConstantsCommon = require('../ConstantsCommon');
const _IAuthRenderer = require('../misc/IAuthRenderer');
const UtilsCommonMin = require('../misc/UtilsCommonMin');

/** @class */
function AuthManager() {
    this.logger = undefined;
    /** @type {_IAuthRenderer} */
    this.renderer = undefined;
    this.processor = undefined;
    this.accessToken = undefined;
    this.timeUntilTokenExpire = undefined;
    this.authInfo = undefined;
    this.localStorage = undefined;
    this.shutdown = false;

    this.loggedOut = false;
    this.loggedOutConfirmed = false;
    this.startupLoggedOut = false;
    this.loggedIn = false;
    this.hasRedirected = false;
    this.refreshingAccessToken = false;

    this.awsCognitoDomain = undefined;
    this.awsCognitoClientId = undefined;
    this.awsCognitoTokenUrl = undefined;
    this.awsCognitoAuthUrl = undefined;
    this.awsCognitoCallbackUrl = undefined;
    this.awsCognitoClientLogoutUrl = undefined;

    this.authFlowRid = 0;
    this.authFlowController = new AbortController();

    this.init = (loggerIn, rendererIn, localStorageIn, processor) => {
        this.logger = loggerIn;
        this.renderer = rendererIn;
        // logger = loggerIn;
        this.localStorage = localStorageIn;
        this.processor = processor;
    };

    this.reinit = () => {
        this.loggedOut = false;
        this.loggedOutConfirmed = false;
        this.startupLoggedOut = false;
        this.loggedIn = false;
        this.hasRedirected = false;
        this.refreshingAccessToken = false;
    };

    this.start = () => {
        this.oneSecondLoopLibMgr();
    };

    this.stop = () => {
        this.shutdown = true;
    };

    this.logError = async (response) => {
        let data;
        if (response.headers.get('Content-Type') && response.headers.get('Content-Type').startsWith('application/json')) {
            data = await response.json();
            this.logger.error(JSON.stringify(data));
        } else {
            data = await response.text();
            this.logger.error(data.substring(0, 200));
        }
        return data;
    };

    this.getDataErrorInfo = async (response, errMsg) => {
        let errMsgFinal;
        let dataError;
        let dataErrorCode;
        const data = await this.logError(response);
        if (response.headers.get('Content-Type') && response.headers.get('Content-Type').startsWith('application/json')) {
            dataError = data.error;
            dataErrorCode = data.errorCode;
            errMsgFinal = `${errMsg}${data.error ? `: ${data.error}` : ''}`;
        } else {
            errMsgFinal = errMsg;
        }
        this.logger.info(errMsgFinal);
        return {
            errMsgFinal,
            dataError,
            dataErrorCode,
        };
    };

    this.getDataError = async (response, errMsg) => {
        const dataErrorInfo = await this.getDataErrorInfo(response, errMsg);
        return dataErrorInfo.errMsgFinal;
    };

    this.showDataErrorToast = async (response, errMsg, silentError = false) => {
        const errMsgFinal = await this.getDataError(response, errMsg);
        if (!silentError) {
            this.renderer.showToast(errMsgFinal);
        }
        return errMsgFinal;
    };

    this.handle401Redirect = async (isAppStartup) => {
        if (!this.hasRedirected) {
            // cannot use await this.logoutConfirm(), to avoid clashing with setting of this.hasRedirected flag.
            this.logoutConfirm(isAppStartup);
            // window.location.href = getLogoutUrl();
            this.hasRedirected = true;
        } else {
            this.logger.error('handle401Redirect: already redirected, skipping...');
        }
    };

    // Need this method to handle 2 more cases in addition to logging in:
    // - social login, add wallet
    // - wallet login, change wallet
    this.signinUsingMetamask = async (address, isChangingAddress, throwError, signMsgFn, addressSource, passwordType, siteUrl) => {
        this.logger.info(`address [${address}]`);
        // NOTE: below will be false for PC
        // const isResignin = window && window.balanceManager && window.balanceManager.userAddressEthMm !== undefined;
        const { address: addressPrev, _addressSource } = authManager.getLocalAddressSource();
        // null for localStorage.getItem(), undefined for variable based
        // TODO: make this consistent.
        const isResignin = addressPrev !== undefined && addressPrev !== null;
        if (!address) {
            this.logger.error('signinUsingMetamask: address is required!');
            let restartDeviceMsg;
            if (addressSource === 'mm') {
                restartDeviceMsg = 'Please restart Metamask and try again.';
            } else if (addressSource === 'bb' || addressSource === 'bbl') {
                restartDeviceMsg = 'Please restart the app and try again.';
            } else if (addressSource === 'ledger') {
                restartDeviceMsg = 'Please restart the Ledger device and try again.';
            } else {
                throw new Error(`Internal error: unknown addressSource [${addressSource}]`);
            }
            const errorMessageFinal = `Failed to signin [error code: 3]. ${restartDeviceMsg}`;
            this.renderer.displayLoginMsg(errorMessageFinal);
            if (isResignin) {
                showToast(errorMessageFinal);
            }
            return null;
        }
        if (isResignin) {
            // if (address !== window.balanceManager.userAddressEthMm) {
            if (address !== addressPrev) {
                showToast(`Wallet address must be the same as previously signed-in [${addressPrev}].`);
                return null;
            }
        }
        const errMsg = 'Failed to signin [error code: 1].';
        try {
            this.renderer.setReqInFlight(true);
            this.renderer.onTx();
            const response = await fetch(
                `${ConstantsCommon.API_URL}/auth-challenge/${address}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            this.renderer.onRx();
            const statusCode = response.status;
            this.logger.info(`signinUsingMetamask statusCode [${statusCode}]`);
            if (statusCode === 200) {
                const {
                    data,
                    domainHash,
                    messageHash,
                    eip712MessageHash,
                } = await response.json();
                const from = address;
                const sig = await signMsgFn(from, data, domainHash, messageHash, eip712MessageHash);
                const accessTokenInfoRefreshToken = await this.validateMetamaskSig(
                    address, data, sig, isChangingAddress, throwError, addressSource, passwordType, siteUrl, isResignin,
                );
                return accessTokenInfoRefreshToken;
                // const params = [from, JSON.stringify(data)];
                // const method = 'eth_signTypedData_v3';
                // web3Metamask.currentProvider.sendAsync({
                //     method,
                //     params,
                //     from,
                // }, (err, result) => {
                //     if (err) {
                //         this.logger.info(err);
                //         if (err.code === -32603 && err.message.indexOf('must match the active chainId') > -1) {
                //             showToast(`Please select the [${bobbobAuth.ConstantsCommon.chainName}] blockchain in MetaMask`);
                //         } else {
                //             showToast(`Failed to sign in${err.code ? ` [${err.code}]` : ''}`);
                //         }
                //         return;
                //     }
                //     if (result.error) {
                //         this.logger.info(`ERROR: `, result);
                //         showToast(result.error.message);
                //         return;
                //     }
                //     const sig = result.result;
                //     this.logger.info(`sig [${sig}]`);
                //     const recovered = sigUtil.recoverTypedSignature({
                //         data: data,
                //         signature: sig,
                //         version: sigUtil.SignTypedDataVersion.V4,
                //     });
                //     this.logger.info(`recovered [${recovered}]`);

                //     if (recovered.toLowerCase() === from.toLowerCase()) {
                //         this.logger.info('Successfully recovered signer as ' + from);
                //         validateMetamaskSig(data, sig, isChangingAddress);
                //     } else {
                //         this.logger.info(`Failed to verify signer when comparing ${result} to ${from}`);
                //         showToast(`Failed to verify signer`);
                //     }
                // });
            } else {
                // $('#preloader-msg').html(errMsg);
                // const errMsgFinal = await this.showDataErrorToast(response, errMsg);
                const errMsgFinal = await this.getDataError(response, errMsg);
                this.logger.info(errMsgFinal);
                this.renderer.displayLoginMsg(errMsgFinal);
                if (throwError) {
                    throw new Error(errMsgFinal);
                }
            }
        } catch (error) {
            this.logger.info(`Failed to process signin-app: [${error.stack || error.message}]`);
            // $('#preloader-msg').html(errMsg);
            // window.showToast(`${errMsg} ${error.message}`);
            this.renderer.displayLoginMsg(`${errMsg} ${error.message}`);
            if (isResignin) {
                showToast(`${errMsg} ${error.message}`);
            }
            // setTimeout(() => displaySigin(), AWS_COGNITO_PKCE_UI_TIMEOUT);
            if (throwError) {
                throw error;
            }
        } finally {
            this.renderer.setReqInFlight(false);
        }
        return null;
    };

    this.processAccessTokenInfoRefreshToken = async (authInfo, accessTokenInfoRefreshToken) => {
        const accessTokenInfo = accessTokenInfoRefreshToken.accessTokenInfo;
        const refreshToken = accessTokenInfoRefreshToken.refreshToken;
        // const idTokenInfo = accessTokenInfoRefreshToken.idTokenInfo;
        const state = {
            accessToken: {
                value: accessTokenInfo.accessToken,
                timeUntilTokenExpire: accessTokenInfo.exp,
            },
            refreshToken,
            authInfo,
        };
        await navigator.locks.request('wallet', async (_lock) => {
            this.localStorage.setItem('metamask-state', JSON.stringify(state));
            window.localStorageCache['metamask-state'] = JSON.stringify(state);
            this.localStorage.removeItem('oauth2authcodepkce-state');
            delete window.localStorageCache['oauth2authcodepkce-state'];
            const now = DateTime.now().valueOf();
            this.localStorage.setItem('sign-in-timestamp', `${now}`);
            window.localStorageCache['sign-in-timestamp'] = `${now}`;
            this.accessToken = accessTokenInfo.accessToken;
            this.timeUntilTokenExpire = accessTokenInfo.exp;
            this.authInfo = state.authInfo;
            // NOTE: only providerName Google used by FE to redirect to AWS for logout. all other types of providerName is for audit purposes
            this.localStorage.setItem('providerName', 'wallet');
            window.localStorageCache['providerName'] = 'wallet';
            this.localStorage.setItem('addressSource', authInfo.addressSource);
            this.localStorage.setItem('address', authInfo.address);
            // Only for bb wallets
            this.localStorage.setItem('passwordType', authInfo.passwordType || '');
            this.localStorage.setItem('siteUrl', authInfo.siteUrl || '');
            // cache for faster in-app access
            window.activeWalletSource = authInfo.addressSource;
            window.activeWallet = authInfo.address;
            window.activeWalletPasswordType = authInfo.passwordType;
            window.activeWalletSiteUrl = authInfo.siteUrl;
            // this.renderer.hideShowLockAppFeature(true);
            this.localStorage.setItem('accessToken', accessTokenInfo.accessToken);
            window.localStorageCache['accessToken'] = accessTokenInfo.accessToken;
            this.localStorage.setItem('timeUntilTokenExpire', `${this.timeUntilTokenExpire}`);
            window.localStorageCache['timeUntilTokenExpire'] = `${this.timeUntilTokenExpire}`;
        });
        // this can be called before app loads
        if (window.clientWsManager) {
            window.clientWsManager.sendWsReAuth();
        }
        // this.checkMultiSignIn();
    };

    // this.checkMultiSignIn = async () => {
    //     // multi sign-in check
    //     // scenario:
    //     // - open tab 1
    //     // - open tab 2
    //     // - sign in tab 1 as user 1
    //     // - sign in tab 1 as user 2
    //     // - tab 1 will still show user 1 but accessToken belongs to user 2
    //     // Uncomment this section to test BE for robustness against this scenario
    //     await navigator.locks.request('sign-in', (_lock) => {
    //         const appId = this.localStorage.getItem('appId');
    //         console.log(`${window.logPrefix()}appId [${appId}]`);
    //         if (appId) {
    //             // Already signed in from another tab
    //             console.log(`${window.logPrefix()}Overriding appId [${appId}] with this.appId [${this.appId}]`);
    //         }
    //         this.localStorage.setItem('appId', this.appId);
    //     });
    // };

    this.validateMetamaskSig = async (address, data, sig, isChangingAddress, throwError, addressSource, passwordType, siteUrl, isResignin = false) => {
        const errMsg = 'Failed to signin [error code: 2]';
        try {
            this.renderer.setReqInFlight(true);
            // remove so UI does not flash when login disappears and preloader appears
            // this.renderer.html('#login-msg', 'Signing in...');
            const hash = data.message.challenge;
            const deadline = data.message.deadline;
            this.renderer.onTx();
            const payload = {
                address,
                hash,
                deadline,
                sig,
                addressSource,
                passwordType,
                siteUrl,
            }
            const response = await fetch(
                // `${ConstantsCommon.API_URL}/auth/${address}/${hash}/${deadline}/${sig}/${addressSource}`,
                `${ConstantsCommon.API_URL}/auth`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                },
            );
            this.renderer.onRx();
            const statusCode = response.status;
            this.logger.info(`validateMetamaskSig statusCode [${statusCode}]`);
            if (statusCode === 200) {
                const accessTokenInfoRefreshToken = await response.json();
                const authInfo = {
                    address,
                    addressSource,
                    hash,
                    deadline,
                    sig,
                    passwordType,
                    siteUrl,
                    // hasWeb2: accessTokenInfoRefreshToken.idTokenInfo.hasWeb2,
                };
                await this.processAccessTokenInfoRefreshToken(authInfo, accessTokenInfoRefreshToken);
                if (!isChangingAddress) {
                    // remove so UI does not flash when login disappears and preloader appears
                    // this.renderer.html('#login-msg', 'Loading app...');
                    // this.renderer.html('#preloader-msg', 'Loading app...');
                    // save one refresh for faster login
                    const refreshApp = false;
                    if (refreshApp) {
                        // setTimeout(() => { window.location.href = 'app'; }, AWS_COGNITO_PKCE_UI_TIMEOUT);
                        // setTimeout(() => { window.location.href = ''; }, AWS_COGNITO_PKCE_UI_TIMEOUT);
                        this.renderer.refreshApp();
                    } else {
                        this.loggedIn = true;
                        this.loggedInSignedWallet = true;
                        // await sleep(ConstantsCommon.AWS_COGNITO_PKCE_UI_TIMEOUT);
                        if (!isResignin) {
                            await this.renderer.onPostLoggedIn();
                        } else {
                            // clear previous redirect flag
                            this.hasRedirected = false;
                            this.loggedOutConfirmed = false;
                            window.clientWsManager.reconnect();
                            $('#modal-logged-out-sign-in').modal('hide');
                            $('#bkg-status').hide();
                        }
                    }
                    return accessTokenInfoRefreshToken;
                } else {
                    // swapping address in MM
                    // await balanceManager.getBalance();
                    throw new Error('Unsupported code flow');
                }
            } else {
                // this.logger.info(`${errMsg}`);
                // const data = await logError(response);
                let isNonInvite = false;
                const dataErrorInfo = await this.getDataErrorInfo(response, errMsg);
                if (dataErrorInfo.dataErrorCode === 10000) {
                    const nonInviteErrorMsg = `<div>${ConstantsCommon.INVITE_ONLY_MSG}</div>`;
                    this.renderer.displayNonInviteMsg(nonInviteErrorMsg);
                    if (isResignin) {
                        showToast(nonInviteErrorMsg);
                    }
                    // setTimeout(() => {
                    //     bobbobAuth.setHtmlShowHide('#login-msg', nonInviteErrorMsg);
                    //     $('#preloader-msg').html(nonInviteErrorMsg);
                    // }, AWS_COGNITO_PKCE_UI_TIMEOUT);
                    isNonInvite = true;
                }
                // if (response.headers.get('Content-Type') && response.headers.get('Content-Type').startsWith('application/json')) {
                //     if (data.errorCode && data.errorCode === 10000) {
                //         const nonInviteErrorMsg = `<div>${ConstantsCommon.INVITE_ONLY_MSG}</div>`;
                //         setTimeout(() => {
                //             bobbobAuth.setHtmlShowHide('#login-msg', nonInviteErrorMsg);
                //             $('#preloader-msg').html(nonInviteErrorMsg);
                //         }, AWS_COGNITO_PKCE_UI_TIMEOUT);
                //         isNonInvite = true;
                //     }
                // }
                if (!isNonInvite) {
                    const errMsgFinal = dataErrorInfo.dataErrorCode === 20000 ? dataErrorInfo.dataError : errMsg;
                    this.renderer.displayLoginMsg(errMsgFinal);
                    if (isResignin) {
                        showToast(errMsgFinal);
                    }
                    // bobbobAuth.setHtmlShowHide('#login-msg', errMsg);
                    // $('#preloader-msg').html(errMsg);
                    // showToast(errMsg);
                }
                if (throwError) {
                    throw new Error(errMsg);
                }
                // setTimeout(() => displaySigin(), AWS_COGNITO_PKCE_UI_TIMEOUT);
            }
        } catch (error) {
            this.logger.info(`${errMsg}: [${error.stack || error.message}]`);
            this.renderer.displayLoginMsg(errMsg);
            if (isResignin) {
                showToast(errMsg);
            }
            // bobbobAuth.setHtmlShowHide('#login-msg', errMsg);
            // $('#preloader-msg').html(errMsg);
            // showToast(errMsg);
            if (throwError) {
                throw error;
            }
        } finally {
            this.renderer.setReqInFlight(false);
        }
        return null;
    };

    // this.reconnectMetaMaskAddress = async (
    //     addressEthMm,
    //     hideSuccess,
    // ) => {
    //     if (!this.authInfo) {
    //         $('.modal').modal('hide');
    //         $('#modal-logged-out').modal('show');
    //         return false;
    //     }
    //     const errMsg = 'Failed to connect MetaMask wallet';
    //     const successMsg = 'MetaMask connected successfully';
    //     try {
    //         this.renderer.hideToast();
    //         $('.button-metamask-connect').prop('disabled', true);
    //         const accessTokenToUse = await this.checkRefreshGetAccessTokenAsync();
    //         const payload = {
    //             addressEthMm,
    //             hash: this.authInfo.hash,
    //             deadline: this.authInfo.deadline,
    //             sig: this.authInfo.sig,
    //         };
    //         this.renderer.onTx();
    //         const response = await fetch(
    //             `${ConstantsCommon.API_URL}/wallet/metamask`,
    //             {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Bearer ${accessTokenToUse}`,
    //                 },
    //                 body: JSON.stringify(payload),
    //             },
    //         );
    //         this.renderer.onRx();
    //         const statusCode = response.status;
    //         this.logger.info(`linkMetaMask statusCode [${statusCode}]`);
    //         if (statusCode === 200) {
    //             // const data = await response.json();
    //             $('#address-eth-mm').html(getAddressTemplate(addressEthMm, 'address-eth-mm'));
    //             // $('#active-wallet').html('');
    //             // $('#active-wallet').append(`<option value="1">${addressEthMm}</option>`);
    //             // $('#active-wallet').val('1');
    //             // activeWalletSource = '0';
    //             // $('#label-active-wallet').html('Active Wallet (Non-Custodial)');
    //             // $('#active-wallet').val(account);
    //             balanceManager.userAddressEthMm = addressEthMm;
    //             // setActiveWallet();
    //             $('#default-to-custodial').prop('checked', false).trigger('change');
    //             // setActiveWallet();
    //             if (!hideSuccess) {
    //                 this.renderer.showToast(successMsg, true);
    //             }
    //             $('.button-metamask-connect').prop('disabled', false);
    //             return true;
    //         } else if (statusCode === 401) {
    //             this.handle401Redirect();
    //         } else {
    //             $('.button-metamask-connect').prop('disabled', false);
    //             await this.showDataErrorToast(response, errMsg);
    //         }
    //     } catch (error) {
    //         $('.button-metamask-connect').prop('disabled', false);
    //         this.logger.info(`${errMsg}: ${error.stack || error.message}`);
    //         this.renderer.showToast(errMsg);
    //     }
    //     return false;
    // };

    this.checkRidX = (rid, currentRid, abortId) => {
        if (rid !== undefined && rid !== currentRid) {
            console.log(`${window.logPrefix()}rid [${rid}] !== currentRid [${currentRid}] abortId [${abortId}]`);
            return false;
        }
        return true;
    };

    this.checkAbortErrorX = (error) => {
        if (error.name === 'AbortError') {
            console.log(`${window.logPrefix()}AbortError`);
            return false;
        }
        return true;
    };

    this.signInApp = async (accessTokenIn, idToken, refreshToken, locationHash, fromLoginForm, signal, rid, ridProviderFn, isResignin = false) => {
        this.startupLoggedOut = false;
        const payload = {
            accessToken: accessTokenIn,
            idToken,
            refreshToken,
        };
        let msgElem;
        if (isResignin) {
            msgElem = '#resignin-msg';
        } else if (fromLoginForm) {
            msgElem = '#login-msg';
        } else {
            // from local storage
            msgElem = '#preloader-msg';
        }
        const errMsg = 'Failed to process signin-app';
        try {
            this.renderer.setReqInFlight(true);
            this.renderer.onTx();
            const response = await fetch(
                `${ConstantsCommon.API_URL}/signin-app`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                    signal,
                },
            );
            this.renderer.onRx();
            if (!this.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            const statusCode = response.status;
            this.logger.info(`signInApp statusCode [${statusCode}]`);
            if (statusCode === 200) {
                // const accessTokenInfo = await response.json();
                const data = await response.json();
                const accessTokenInfo = data.accessTokenInfo;
                const idTokenInfo = data.idTokenInfo;
                if (!this.checkRidX(rid, ridProviderFn(), '1a')) {
                    return;
                }
                this.accessToken = accessTokenInfo.accessToken;
                this.timeUntilTokenExpire = accessTokenInfo.exp;
                this.authInfo = undefined;
                await navigator.locks.request('wallet', async (_lock) => {
                    this.localStorage.setItem('providerName', idTokenInfo.providerName);
                    window.localStorageCache['providerName'] = idTokenInfo.providerName;
                    this.localStorage.removeItem('addressSource');
                    this.localStorage.removeItem('address');
                    this.localStorage.removeItem('siteUrl');
                    this.localStorage.removeItem('passwordType');
                    window.activeWalletSource = undefined;
                    window.activeWallet = undefined;
                    window.activeWalletPasswordType = undefined;
                    window.activeWalletSiteUrl = undefined;
                    // this.renderer.hideShowLockAppFeature(idTokenInfo.providerName !== 'cognito');
                    // Done further up to better reflect accessToken expiry, ie not not take into account call to BE
                    // this.localStorage.setItem('sign-in-timestamp', luxon.DateTime.now().valueOf());
                    this.localStorage.setItem('accessToken', accessTokenInfo.accessToken);
                    window.localStorageCache['accessToken'] = accessTokenInfo.accessToken;
                    this.localStorage.setItem('timeUntilTokenExpire', `${this.timeUntilTokenExpire}`);
                    window.localStorageCache['timeUntilTokenExpire'] = `${this.timeUntilTokenExpire}`;
                });
                if (!isResignin) {
                    // this.checkMultiSignIn();
                    // $(msgElem).html('Init app...');
                    bobbobAuth.setHtmlShowHide(msgElem, 'Init app...');
                    // don't need to redirect, waste a round trip cycle
                    // setTimeout(() => { window.location.href = ''; }, AWS_COGNITO_PKCE_UI_TIMEOUT);
                    // startupLoggedOut = true;
                    // don't use push to add history, replace current one
                    // window.history.pushState("", "", '/');
                    // if (!isCustomUi) {
                    window.history.replaceState('', '', `/${locationHash || ''}`);
                    // }
                }
                this.loggedIn = true;
                this.loggedInSignedWallet = false;
                // NOTE: this.renderer.onPostLoggedIn() is not needed for PKCE because
                // page is refreshed from cognito, so onPostLoggedIn() will be called at the end of page load
                // for MM, the page is not redirected, but uses the wallet instead, so onPostLoggedIn() is needed there.
                // $('#top-button-metamask-connect').hide();
                if (!isResignin) {
                    if (fromLoginForm) {
                        await this.renderer.onPostLoggedIn();
                    }
                } else {
                    // When we resignin, there is a special case for BB remote. It is reliant on web2 since
                    // keys are stored in BE. So, if we were previously signed in by BB wallet, we need to
                    // "disconnect" the wallet, ie reset the UI to have no active wallet
                    if (ConstantsCommon.BB_REMOTE_RESIGN_IN_WEB2) {
                        window.bbWallet = null;
                        balanceManager.onDisconnectWallet();
                    }
                    // clear previous redirect flag
                    this.hasRedirected = false;
                    this.loggedOutConfirmed = false;
                    window.clientWsManager.reconnect();
                    $('#modal-logged-out-sign-in').modal('hide');
                    $('#bkg-status').hide();
                }
            } else {
                // this.logger.info(`${errMsg}`);
                // const data = await bobbob.HtmlHelper.logError(response);
                let isNonInvite = false;
                const dataErrorInfo = await this.getDataErrorInfo(response, errMsg);
                if (!this.checkRidX(rid, ridProviderFn(), '1a')) {
                    return;
                }
                if (dataErrorInfo.dataErrorCode === 10000) {
                    // $(msgElem).html(ConstantsCommon.INVITE_ONLY_MSG);
                    bobbobAuth.setHtmlShowHide(msgElem, ConstantsCommon.INVITE_ONLY_MSG);
                    isNonInvite = true;
                }
                // if (response.headers.get('Content-Type') && response.headers.get('Content-Type').startsWith('application/json')) {
                //     if (data.errorCode && data.errorCode === 10000) {
                //         $('#preloader-msg').html(bobbobAuth.ConstantsCommon.INVITE_ONLY_MSG);
                //         isNonInvite = true;
                //     }
                // }
                if (!isNonInvite) {
                    if (!ConstantsCommon.LOGIN_LANDING_ENABLED) {
                        // $(msgElem).html('Signing in...');
                        bobbobAuth.setHtmlShowHide(msgElem, 'Signing in...');
                        setTimeout(() => { return oauth.fetchAuthorizationCode(); }, ConstantsCommon.AWS_COGNITO_PKCE_UI_TIMEOUT);
                    } else {
                        // $(msgElem).html(`Failed to sign-in [${statusCode}]`);
                        bobbobAuth.setHtmlShowHide(msgElem, `Failed to sign-in [${statusCode}]`);
                    }
                }
                this.startupLoggedOut = true;
            }
        } catch (error) {
            this.logger.info(`${errMsg}: [${error.stack || error.message}]`);
            if (!this.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            if (!this.checkAbortErrorX(error)) {
                return;
            }
            if (!ConstantsCommon.LOGIN_LANDING_ENABLED) {
                // $(msgElem).html('Signing in...');
                bobbobAuth.setHtmlShowHide(msgElem, 'Signing in...');
                setTimeout(() => { return oauth.fetchAuthorizationCode(); }, ConstantsCommon.AWS_COGNITO_PKCE_UI_TIMEOUT);
            } else {
                // $(msgElem).html(`Failed to sign-in [${error.message}]`);
                bobbobAuth.setHtmlShowHide(msgElem, `Failed to sign-in [${error.message}]`);
            }
            this.startupLoggedOut = true;
        } finally {
            this.renderer.setReqInFlight(false);
        }
    };

    this.validateStoreOpts = (addressToMatch, passwordTypeToMatch, siteUrlToMatch) => {
        if (!addressToMatch || typeof addressToMatch !== 'string') {
            console.log(`${window.logPrefix()}addressToMatch is required and must be string type [${new Error().stack}]`);
            throw new Error(`Internal error: addressToMatch is required!`);
        }
        if (!passwordTypeToMatch || typeof passwordTypeToMatch !== 'string') {
            console.log(`${window.logPrefix()}passwordTypeToMatch is required and must be string type [${new Error().stack}]`);
            throw new Error(`Internal error: passwordTypeToMatch is required!`);
        }
        if (passwordTypeToMatch === '0') {
            if (!siteUrlToMatch || typeof siteUrlToMatch !== 'string') {
                console.log(`${window.logPrefix()}siteUrlToMatch is required and must be string type [${new Error().stack}]`);
                throw new Error(`Internal error: siteUrlToMatch is required!`);
            }
        }
    };

    this.getLocalAddressSource = () => {
        // Use cached values from memory, to allow different wallets to connect from
        // different tabs
        // const addressSource = localStorage.getItem('addressSource');
        // const address = localStorage.getItem('address');
        const address = window.activeWallet;
        const addressSource = window.activeWalletSource;
        const passwordType = window.activeWalletPasswordType;
        const siteUrl = window.activeWalletSiteUrl;
        return {
            address,
            addressSource,
            passwordType,
            siteUrl,
        };
    };

    // Deprecated, use getLocalAddressSource
    this.getRegisteredAddressSource = () => {
        let registeredAddress;
        let registeredAddressSource;
        // authManager.authInfo is based on access token to BE
        // balanceManager.userAddressEthMm is wallet stored in BE
        // we set active wallet based on latter
        // if (authManager.authInfo) {
        //     registeredAddress = authManager.authInfo.address;
        //     registeredAddressSource = authManager.authInfo.addressSource;
        //     console.log(`${window.logPrefix()}app authManager.authInfo.addressSource [${authManager.authInfo.addressSource}]`);
        // } else if (window.balanceManager.userAddressEthMm) {
        //     registeredAddress = window.balanceManager.userAddressEthMm;
        //     registeredAddressSource = window.balanceManager.userAddressEthMmSource;
        //     console.log(`${window.logPrefix()}app window.balanceManager.userAddressEthMmSource [${window.balanceManager.userAddressEthMmSource}]`);
        // }
        // balanceManager may not be initialised yet, ie account changed in login page
        if (window.balanceManager && window.balanceManager.userAddressEthMm) {
            registeredAddress = window.balanceManager.userAddressEthMm;
            registeredAddressSource = window.balanceManager.userAddressEthMmSource;
            console.log(`${window.logPrefix()}app window.balanceManager.userAddressEthMmSource [${window.balanceManager.userAddressEthMmSource}]`);
        }
        return {
            registeredAddress,
            registeredAddressSource,
        };
    };

    this.logoutConfirm = (isAppStartup = false) => {
        if (this.loggedOut) {
            this.logger.error('logoutConfirm: already logged out!');
            return;
        }
        if (this.loggedOutConfirmed) {
            this.logger.error('logoutConfirm: already logged out confirmed!');
            return;
        }
        this.loggedOutConfirmed = true;
        this.processor.onLogoutConfirm(isAppStartup);
    };

    this.handleWeb2Resignin = () => {
        $('#resignin-bb-section').hide();
        $('#resignin-bbl-section').hide();
        $('#resignin-mm-section').hide();
        $('#resignin-ledger-section').hide();

        $('#resignin-cognito-section').show();
        $('#button-logged-out-sign-in-ok').show();

        const username = $('#username').val();
        // if (!username) {
        //     this.logout();
        // } else {
        console.log(`${window.logPrefix()}setting resignin-username to username [${username}]`);
        $('#resignin-username').val(username);
        // $('.modal').modal('hide');
        $('#resignin-password').val('');
    };

    this.lockApp = (isFromUser = false) => {
        const okToProceed = bobbobAuth.lockAppOkToProceed();
        if (!okToProceed) {
            throw new Error(`Internal error: okToProceed [${okToProceed}] in lockApp`);
        }
        this.logger.info(`lockApp isFromUser [${isFromUser}] $('#username').val() [${$('#username').val()}]`);
        const isModalOpen = $('#modal-logged-out-sign-in').hasClass('in') || $('#modal-logged-out-sign-in').hasClass('show');
        if (isModalOpen) {
            this.logger.info('lockApp modal already open');
            return;
        }
        // scenario:
        // - lock app
        // - save page for offline (or close tab)
        // - open offline page (or ctrl-shift t to reopen tab)
        // - locked screen without blank username appears
        // const { _registeredAddress, registeredAddressSource } = this.getRegisteredAddressSource();
        const { address: registeredAddress, addressSource: registeredAddressSource } = authManager.getLocalAddressSource();

        let msg;
        if (isFromUser) {
            // Need to clear accessToken which hash address, otherwise on browser refresh will restore app!
            localStorage.removeItem('accessToken');
            delete window.localStorageCache['accessToken'];
            localStorage.removeItem('metamask-state');
            delete window.localStorageCache['metamask-state'];
            this.localStorage.removeItem('oauth2authcodepkce-state');
            delete window.localStorageCache['oauth2authcodepkce-state'];

            // TODO: what happens if session times out when is mode where user manually locked out?
            // leave it for now, since cognitoLogin handles both cases
            // No, we allow updating title and msg if modal is open, better UX.
            $('#modal-logged-out-sign-in-title').html('App Locked');
            msg = '<div>Please sign-in to unlock the app.</div>';
            // $('#modal-logged-out-sign-in-msg').html('Please sign-in to unlock the app.');
        } else {
            msg = '<div>Your session has timed out, please sign-in again. Otherwise, sign-out to end your session.</div>';
            $('#modal-logged-out-sign-in-title').html('Session Timed Out');
            // $('#modal-logged-out-sign-in-msg').html('Your session has timed out, please sign-in again. Otherwise, sign-out to end your session.');
        }
        if (registeredAddress) {
            msg += /* html */`
                <div style="font-size:small;margin-top:20px;">Address</div>
                <div id="address-session-timeout" style="display: flex; align-items: center;">
                    <div class="currency hex-address">${registeredAddress}</div>
                    <i class="fa fa-copy" style="margin-left: 5px;" aria-hidden="true"
                        data-bb-cb-modal="#modal-order-book"
                        data-bb-cb-highlight="parent"
                        data-bb-cb-text=""
                        data-bb-cb-text-raw="${registeredAddress}"
                        onclickm="bobbob.CbHelper.copyToClipboardData(event, this);return false;"
                    ></i>
                    <i class="fa-solid fa-arrow-up-right-from-square" style="margin-left: 5px;" aria-hidden="true"
                        data-bb-es-txn=""
                        data-bb-es-address="${registeredAddress}"
                        data-bb-es-contract=""
                        data-bb-es-token=""
                        onclickm="window.open('https://sepolia.etherscan.io/address/${registeredAddress}', '_blank');return false;"
                    ></i>
                </div>
            `;
        }
        $('#modal-logged-out-sign-in-msg').html(msg);
        if (registeredAddressSource === 'mm') {
            $('#resignin-bb-section').hide();
            $('#resignin-bbl-section').hide();
            $('#resignin-ledger-section').hide();
            $('#resignin-cognito-section').hide();
            $('#button-logged-out-sign-in-ok').hide();

            $('#resignin-mm-section').show();
            $('#resignin-mm-button').prop('disabled', false);
            const src = bobbobAuth.getIconSrc(registeredAddressSource);
            bobbobAuth.checkSetButtonImg('#resignin-mm-button img', src);
        } else if (registeredAddressSource === 'bb') {
            // BB remote needs to talk to BE to get encrypted key, but that endpoint is protected
            // and the access token has expired! We keep it private for now and display web2 resignin
            // This means BB remote is reliant on web2 for now.
            // No, we keep the encrypted key in memory for resignin
            if (ConstantsCommon.BB_REMOTE_RESIGN_IN_WEB2) {
                this.handleWeb2Resignin();
            } else {
                $('#resignin-bbl-section').hide();
                $('#resignin-ledger-section').hide();
                $('#resignin-cognito-section').hide();
                $('#button-logged-out-sign-in-ok').hide();
                $('#resignin-mm-section').hide();

                $('#resignin-bb-section').show();
                $('#resignin-bb-button').prop('disabled', false);
                const src = bobbobAuth.getIconSrc(registeredAddressSource);
                bobbobAuth.checkSetButtonImg('#resignin-bb-button img', src);
            }
        } else if (registeredAddressSource === 'bbl') {
            $('#resignin-bb-section').hide();
            $('#resignin-ledger-section').hide();
            $('#resignin-cognito-section').hide();
            $('#button-logged-out-sign-in-ok').hide();
            $('#resignin-mm-section').hide();

            $('#resignin-bbl-section').show();
            $('#resignin-bbl-button').prop('disabled', false);
            const src = bobbobAuth.getIconSrc(registeredAddressSource);
            bobbobAuth.checkSetButtonImg('#resignin-bbl-button img', src);
        } else if (registeredAddressSource === 'ledger') {
            $('#resignin-bb-section').hide();
            $('#resignin-bbl-section').hide();
            $('#resignin-mm-section').hide();
            $('#resignin-cognito-section').hide();
            $('#button-logged-out-sign-in-ok').hide();

            $('#resignin-ledger-section').show();
            $('#resignin-ledger-button').prop('disabled', false);
            const src = bobbobAuth.getIconSrc(registeredAddressSource);
            bobbobAuth.checkSetButtonImg('#resignin-ledger-button img', src);
        } else {
            this.handleWeb2Resignin();
        }
        $('#modal-logged-out-sign-in').modal('show');
        // }
    };

    this.logout = () => {
        if (this.loggedOut) {
            this.logger.error('logout: already logged out!');
            return;
        }
        this.loggedOut = true;
        this.processor.onLogout();
    };

    this.getCognitoUserSession = () => {
        return new Promise((resolve, reject) => {
            authManager.cognitoUser.getSession((err, session) => {
                if (err) {
                    console.log(`${window.logPrefix()}Failed to get session [${err.message || JSON.stringify(err)}]`);
                    reject(new Error(`Failed to get session: ${err.message}`));
                    return;
                }
                console.log(`${window.logPrefix()}session [${JSON.stringify(session, null, 4)}] [${session.isValid()}]`);
                resolve(session);
            });
        });
    };

    this.refreshCognitoUserSession = async (cognitoUser, state, refreshToken, isAppStartup = false) => {
        return new Promise((resolve, reject) => {
            cognitoUser.refreshSession(refreshToken, async (err, session) => {
                if (err) {
                    this.logger.info(`cognitoUser refreshSession error: ${err.stack || err.message}`);
                    reject(new Error(`cognitoUser refreshSession error: ${err.stack || err.message}`));
                } else {
                    this.logger.info(`new session [${JSON.stringify(session, null, 4)}`);
                    // session.getIdToken().getJwtToken();
                    // this.cognitoSession = session;
                    const exp = session.accessToken.payload.exp;
                    const stateAccessToken = {
                        value: session.accessToken.jwtToken,
                        clockDrift: session.clockDrift,
                        // payload: session.accessToken.payload,
                        expiry: (new Date(exp * 1000)).toString(),
                        timeUntilTokenExpire: exp,
                    };
                    await this.updateAccessToken(state, stateAccessToken, exp, isAppStartup);
                    resolve();
                }
            });
        });
    };

    this.updateAccessToken = async (state, stateAccessToken, exp, isAppStartup = false) => {
        this.accessToken = stateAccessToken.value;
        // this.timeUntilTokenExpire = Math.floor((new Date(Date.now() + (exp * 1000))).valueOf() / 1000);
        this.timeUntilTokenExpire = exp;
        this.authInfo = undefined;
        await navigator.locks.request('wallet', async (_lock) => {
            if (ConstantsCommon.AWS_COGNITO_LOCAL_STORAGE) {
                this.localStorage.setItem('accessToken', this.accessToken);
                window.localStorageCache['accessToken'] = this.accessToken;
                this.localStorage.setItem('timeUntilTokenExpire', `${this.timeUntilTokenExpire}`);
                window.localStorageCache['timeUntilTokenExpire'] = `${this.timeUntilTokenExpire}`;

                state.accessToken = stateAccessToken;
                this.localStorage.setItem('oauth2authcodepkce-state', JSON.stringify(state));
                window.localStorageCache['oauth2authcodepkce-state'] = JSON.stringify(state);
                this.localStorage.removeItem('metamask-state');
                delete window.localStorageCache['metamask-state'];
            }
        });
        const luxonNow = DateTime.now();
        const timeMs = (this.timeUntilTokenExpire * 1000) - luxonNow.valueOf();
        const timeUntilExpire = UtilsCommonMin.formatTimeUtil(timeMs);
        this.logger.info(`new accessToken timeUntilExpire ${timeUntilExpire}`);
        // clientWsManager can be undefined when app first starts up
        // refer to processMetamaskState()
        if (!isAppStartup) {
            window.clientWsManager.sendWsReAuth();
        }
    };

    this.refreshAccessTokenFromCognitoServer = async (state, isAppStartup) => {
        const refreshToken = state.refreshToken.value;
        const url = this.awsCognitoTokenUrl;
        const payload = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${this.awsCognitoClientId}`;
        // const request = $.ajax({
        //     method: 'POST',
        //     url,
        //     payload,
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     },
        // })
        // request.done((data) => {
        //     const accessTokenInfo = data;
        //     // this.logger.info(`accessTokenInfo [${JSON.stringify(accessTokenInfo)}]`);
        //     accessToken = accessTokenInfo['access_token'];
        //     authManager.timeUntilTokenExpire = Math.floor((new Date(Date.now() + (accessTokenInfo['expires_in'] * 1000))).valueOf() / 1000);
        //     if (AWS_COGNITO_LOCAL_STORAGE) {
        //         localStorage.setItem('accessToken', accessToken);
        //         localStorage.setItem('timeUntilTokenExpire', authManager.timeUntilTokenExpire);

        //         state.accessToken.value = accessToken;
        //         state.accessToken.timeUntilTokenExpire = authManager.timeUntilTokenExpire;
        //         localStorage.setItem('oauth2authcodepkce-state', JSON.stringify(state));
        //     }
        //     const luxonNow = luxon.DateTime.now();
        //     const timeMs = (authManager.timeUntilTokenExpire * 1000) - luxonNow.valueOf();
        //     const timeUntilExpire = formatTimeUtil(timeMs);
        //     this.logger.info(`new accessToken timeUntilExpire ${timeUntilExpire}`);
        //     sendWsReAuth();
        //     authManager.refreshingAccessToken = false;
        // });
        // request.fail((jqXHR, textStatus, errorThrown) => {
        //     authManager.refreshingAccessToken = false;
        //     this.logger.info(`failed to get refresh access token`);
        //     $('#bkg-status').show();
        //     if (jqXHR.status === 400) {
        //         // refresh token has expired (NOTE: 400 from token endpoint)
        //         authManager.handle401Redirect();
        //     }
        // });
        try {
            this.renderer.setReqInFlight(true);
            this.renderer.onTx();
            const response = await fetch(
                url,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: payload,
                },
            );
            this.renderer.onRx();
            const statusCode = response.status;
            this.logger.info(`refreshAccessToken statusCode [${statusCode}]`);
            if (statusCode === 200) {
                const data = await response.json();
                const accessTokenInfo = data;
                // this.logger.info(`accessTokenInfo [${JSON.stringify(accessTokenInfo)}]`);
                const exp = Math.floor((new Date(Date.now() + (accessTokenInfo.expires_in * 1000))).valueOf() / 1000);
                const stateAccessToken = {
                    value: accessTokenInfo.access_token,
                    expiry: (new Date(exp * 1000)).toString(),
                    timeUntilTokenExpire: exp,
                };
                await this.updateAccessToken(state, stateAccessToken, exp, isAppStartup);
            } else if (statusCode === 400) {
                const responseText = await response.text();
                this.logger.info(`failed to get refresh access token [${responseText}]`);
                // refresh token has expired (NOTE: 400 from token endpoint)
                this.handle401Redirect(isAppStartup);
                $('#bkg-status').show();
            } else {
                const responseText = await response.text();
                // all other errors (ie if iDP is down, we are down), we relogin
                this.logger.info(`failed to get refresh access token [${responseText}]`);
                // refresh token has expired (NOTE: 400 from token endpoint)
                this.handle401Redirect(isAppStartup);
                $('#bkg-status').show();
            }
        } catch (error) {
            this.logger.info(`failed to get refresh access token: ${error.stack || error.message}`);
            $('#bkg-status').show();
            throw error;
        } finally {
            this.refreshingAccessToken = false;
            this.renderer.setReqInFlight(false);
        }
    };

    this.refreshAccessTokenUsingCognitoApi = async (state, username, isAppStartup) => {
        try {
            // const sessionCurrent = await this.getCognitoUserSession();
            // const refreshToken = sessionCurrent.getRefreshToken(); // receive session from calling cognitoUser.getSession()
            // const refreshToken = state.refreshToken.value;
            const idTokenTmp = new bobbobAuth.AmazonCognitoIdentity.CognitoIdToken({
                IdToken: state.idToken.value,
            });
            const accessTokenTmp = new bobbobAuth.AmazonCognitoIdentity.CognitoAccessToken({
                AccessToken: state.accessToken.value,
            });
            const refreshTokenTmp = new bobbobAuth.AmazonCognitoIdentity.CognitoRefreshToken({
                RefreshToken: state.refreshToken.value,
            });
            const clockDrift = Number.parseInt(state.accessToken.clockDrift, 10) || 0;
            const sessionTmp = new bobbobAuth.AmazonCognitoIdentity.CognitoUserSession({
                IdToken: idTokenTmp,
                AccessToken: accessTokenTmp,
                RefreshToken: refreshTokenTmp,
                clockDrift,
            });
            const refreshToken = sessionTmp.getRefreshToken();
            const poolData = {
                UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
                ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
            };
            const userPool = new bobbobAuth.AmazonCognitoIdentity.CognitoUserPool(poolData);
            const userData = {
                Username: username,
                Pool: userPool,
            };
            const cognitoUser = new bobbobAuth.AmazonCognitoIdentity.CognitoUser(userData);
            await this.refreshCognitoUserSession(cognitoUser, state, refreshToken, isAppStartup);
        } catch (error) {
            this.logger.info(`Failed to refresh access token: ${error.stack || error.message}`);
            $('#bkg-status').show();
            if (error.message && error.message.indexOf('NotAuthorizedException: Refresh Token has expired') > -1) {
                // refresh token expired
                this.logger.info('Found token expired error, not throwing error upstream');
            } else {
                throw error;
            }
        } finally {
            this.refreshingAccessToken = false;
        }
    };

    this.refreshAccessToken = async (isAppStartup = false) => {
        // also check if hasRedirected (eg refresh token expired) or user logged out
        if (this.refreshingAccessToken || this.hasRedirected || this.loggedOut || this.loggedOutConfirmed) {
            this.logger.error(`refreshingAccessToken [${this.refreshingAccessToken}] hasRedirected [${this.hasRedirected}] `
                + `loggedOutConfirmed [${this.loggedOutConfirmed}] startupLoggedOut [${this.startupLoggedOut}] loggedOut [${this.loggedOut}]`);
            return;
        }
        this.refreshingAccessToken = true;
        $('#bkg-status').hide();
        if (ConstantsCommon.AWS_COGNITO_ENABLED) {
            // const state = JSON.parse(this.localStorage.getItem('oauth2authcodepkce-state') || '{}');
            const state = JSON.parse(window.localStorageCache['oauth2authcodepkce-state'] || '{}');
            if (!state.refreshToken) {
                // refresh token cleared from local storage
                this.handle401Redirect(isAppStartup);
                this.refreshingAccessToken = false;
                this.logger.info('empty state.refreshToken');
                return;
            }
            if (ConstantsCommon.AWS_COGNITO_HOSTED_UI) {
                await this.refreshAccessTokenFromCognitoServer(state, isAppStartup);
            } else {
                const keyPrefix = `CognitoIdentityServiceProvider.${ConstantsCommon.awsOptionsPkce.awsCognitoClientId}`;
                const lastUserKey = `${keyPrefix}.LastAuthUser`;
                // const username = this.localStorage.getItem(lastUserKey);
                const username = window.localStorageCognitoCache[lastUserKey];
                if (username) {
                    // NOTE: below will clear LastAuthUser in local state
                    await this.refreshAccessTokenUsingCognitoApi(state, username, isAppStartup);
                } else {
                    // this.logger.info('No username from local storage, not refreshing access token...');
                    this.logger.info('No username from local storage, assuming social login...');
                    await this.refreshAccessTokenFromCognitoServer(state, isAppStartup);
                }
            }
        } else {
            // const accessTokenToUse = await checkRefreshGetAccessTokenAsync();
            // const accessTokenToUse = getAccessToken();
            // const request = $.ajax({
            //     method: 'POST',
            //     url: `/refresh-access-token`,
            //     beforeSend: function (xhr) {
            //     if (bobbob.ConstantsCommon.AWS_COGNITO_ENABLED) {
            //         xhr.setRequestHeader('Authorization', `Bearer ${accessTokenToUse}`);
            //     }
            // },
            // })
            // request.done((data) => {
            //     const accessTokenInfo = data.accessTokenInfo;
            //     accessToken = accessTokenInfo.accessToken;
            //     authManager.timeUntilTokenExpire = accessTokenInfo.exp;
            //     if (AWS_COGNITO_LOCAL_STORAGE) {
            //         localStorage.setItem('accessToken', accessToken);
            //         localStorage.setItem('timeUntilTokenExpire', authManager.timeUntilTokenExpire);
            //     }
            //     const luxonNow = luxon.DateTime.now();
            //     const timeMs = (authManager.timeUntilTokenExpire * 1000) - luxonNow.valueOf();
            //     const timeUntilExpire = formatTimeUtil(timeMs);
            //     this.logger.info(`new accessToken timeUntilExpire ${timeUntilExpire}`);
            //     sendWsReAuth();
            //     authManager.refreshingAccessToken = false;
            // });
            // request.fail((jqXHR, textStatus, errorThrown) => {
            //     authManager.refreshingAccessToken = false;
            //     this.logger.info(`failed to get refresh access token`);
            //     $('#bkg-status').show();
            //     if (jqXHR.status === 401) {
            //         // refresh token has expired
            //         authManager.handle401Redirect();
            //     }
            // });
            // return request;

            try {
                this.renderer.setReqInFlight(true);
                const accessTokenToUse = this.getAccessToken();
                this.renderer.onTx();
                const response = await fetch(
                    '/refresh-access-token',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessTokenToUse}`,
                        },
                    },
                );
                this.renderer.onRx();
                const statusCode = response.status;
                this.logger.info(`refreshAccessToken statusCode [${statusCode}]`);
                if (statusCode === 200) {
                    const data = await response.json();
                    const accessTokenInfo = data.accessTokenInfo;
                    this.accessToken = accessTokenInfo.accessToken;
                    this.timeUntilTokenExpire = accessTokenInfo.exp;
                    this.authInfo = undefined;
                    await navigator.locks.request('wallet', async (_lock) => {
                        if (ConstantsCommon.AWS_COGNITO_LOCAL_STORAGE) {
                            localStorage.setItem('accessToken', this.accessToken);
                            window.localStorageCache['accessToken'] = this.accessToken;
                            localStorage.setItem('timeUntilTokenExpire', `${this.timeUntilTokenExpire}`);
                            window.localStorageCache['timeUntilTokenExpire'] = `${this.timeUntilTokenExpire}`;
                        }
                    });
                    const luxonNow = DateTime.now();
                    const timeMs = (this.timeUntilTokenExpire * 1000) - luxonNow.valueOf();
                    const timeUntilExpire = UtilsCommonMin.formatTimeUtil(timeMs);
                    this.logger.info(`new accessToken timeUntilExpire ${timeUntilExpire}`);
                    if (!isAppStartup) {
                        window.clientWsManager.sendWsReAuth();
                    }

                } else if (statusCode === 401) {
                    const responseText = await response.text();
                    this.logger.info(`failed to get refresh access token [${responseText}]`);
                    // refresh token has expired
                    this.handle401Redirect(isAppStartup);
                    $('#bkg-status').show();
                } else {
                    const responseText = await response.text();
                    // all other errors (ie if iDP is down, we are down), we relogin
                    this.logger.info(`failed to get refresh access token [${responseText}]`);
                    // refresh token has expired (NOTE: 400 from token endpoint)
                    this.handle401Redirect(isAppStartup);
                    $('#bkg-status').show();
                }
            } catch (error) {
                this.logger.info(`failed to get refresh access token: ${error.stack || error.message}`);
                $('#bkg-status').show();
                throw error;
            } finally {
                this.refreshingAccessToken = false;
                this.renderer.setReqInFlight(false);
            }
        }
    };

    this.getAccessToken = () => {
        if (ConstantsCommon.AWS_COGNITO_LOCAL_STORAGE) {
            // return this.localStorage.getItem('accessToken');
            return window.localStorageCache['accessToken'];

        }
        return this.accessToken;
    };

    this.getTimeUntilTokenExpire = () => {
        if (ConstantsCommon.AWS_COGNITO_LOCAL_STORAGE) {
            // return this.localStorage.getItem('timeUntilTokenExpire');
            return window.localStorageCache['timeUntilTokenExpire'];
        }
        return this.timeUntilTokenExpire;
    };

    this.getSiteUrl = () => {
        const host = window.location.host.replace(/:.*/, '');
        const siteUrl = `${host}${window.location.port ? `:${window.location.port}` : ''}`;
        return siteUrl;
    };

    this.getSiteUrlParams = (paramIn) => {
        const siteUrl = this.getSiteUrl();
        const payload = {
            siteUrl,
            ...paramIn
        };
        const params = Qs.stringify(payload, { encode: false });
        return params;
    };

    // NOTE: cannot use background timer to fetch/refresh token bcos in browser,
    // timer can be slowed down, and outbound fetch can be stopped/suspended.
    this.checkRefreshGetAccessTokenAsync = async (isAppStartup = false) => {
        // refresh token currently supported in cognito server auth flow
        if (ConstantsCommon.AWS_COGNITO_ENABLED) {
            // console.log(`${window.logPrefix()}#dark-theme [${$('#dark-theme').prop('checked')}]`);
            // console.log(`${window.logPrefix()}#tray-show-queue-info [${$('#tray-show-queue-info').prop('checked')}]`);
            await navigator.locks.request('my_resource', async (_lock) => {
                // const state = JSON.parse(this.localStorage.getItem('oauth2authcodepkce-state') || '{}');
                const state = JSON.parse(window.localStorageCache['oauth2authcodepkce-state'] || '{}');
                // alert(`navigator.locks [${JSON.stringify(navigator.locks)}]`);
                if (state.refreshToken) {
                    const luxonNow = DateTime.now();
                    const timeMs = (state.accessToken.timeUntilTokenExpire * 1000) - luxonNow.valueOf();
                    const timeUntilExpire = UtilsCommonMin.formatTimeUtil(timeMs);
                    // console.log(`${window.logPrefix()}#dark-theme [${$('#dark-theme').prop('checked')}]`);
                    // console.log(`${window.logPrefix()}#tray-show-queue-info [${$('#tray-show-queue-info').prop('checked')}]`);
                    this.logger.info(`timeUntilExpire [${timeUntilExpire}]`);
                    if (timeMs < 35 * 1000) {
                        // need current access token to be valid for refresh, until we get PKCE client side refresh going.
                        if (timeMs > 5000 || ConstantsCommon.AWS_COGNITO_PKCE_ENABLED) {
                            // results can be undefined, in which case it will just pass immediately
                            // try {
                            await this.refreshAccessToken(isAppStartup);
                            // } catch (err) {
                            // this.logger.info(`failed to refresh access token: ${err.message}`);
                            // will get timed_out when mobile goes inactive, we throw to fail the action instead of 401 from app
                            // throw err;
                            // }
                        } else {
                            // if access token has timed out, refresh will fail anyway, so we redirect.
                            // authManager.handle401Redirect();
                        }
                    }
                }
            });
        }
        return this.getAccessToken();
    };

    this.sleepWithSignal = async (ms, signal) => {
        if (ms < 1000) {
            throw new Error('ms must be > 1000');
        }
        let timerHandle;
        let loopCounter = 0;
        const start = bobbobAuth.luxon.DateTime.now().valueOf();
        const oneSecLoop = () => {
            try {
                clearTimeout(timerHandle);
                timerHandle = null;

                loopCounter += 1;
                if (loopCounter % 5 === 0) {
                    console.log(`${window.logPrefix()}loopCounter [${loopCounter}]`);
                }
                if (signal.aborted) {
                    console.log(`${window.logPrefix()}signal.aborted [${signal.aborted}], returning...`);
                    signal.removeEventListener("abort", abortHandler);
                    this.resolve();
                    return;
                }
                const elapsed = bobbobAuth.luxon.DateTime.now().valueOf() - start;
                const waitTime = ms - elapsed;
                if (waitTime <= 0) {
                    signal.removeEventListener("abort", abortHandler);
                    this.resolve();
                    return;
                }
                timerHandle = setTimeout(oneSecLoop, waitTime);
            } catch (err) {
                HtmlHelper.logTryCatch('HtmlHelper Failed to run 1s loop sleep', err);
                const elapsed = bobbobAuth.luxon.DateTime.now().valueOf() - start;
                const waitTime = ms - elapsed;
                if (waitTime <= 0) {
                    signal.removeEventListener("abort", abortHandler);
                    this.resolve();
                    return;
                }
                timerHandle = setTimeout(oneSecLoop, waitTime);
            }
        }

        const abortHandler = () => {
            const elapsed = bobbobAuth.luxon.DateTime.now().valueOf() - start;
            console.log(`${window.logPrefix()}HtmlHelper aborted elapsed [${elapsed}]`);
            clearTimeout(timerHandle); // Stop the operation
            timerHandle = null;
            signal.removeEventListener("abort", abortHandler);
            this.resolve();
        };

        const promise = new Promise((resolve) => {
            // Listen for the abort event
            signal.addEventListener("abort", abortHandler);
            // console.log(`${window.logPrefix()}HtmlHelper added abort listener`);
            this.resolve = resolve;
            oneSecLoop();
        });
        return promise;
    };

    // Moved from TimerUpdater so we can support Ledger/BBWallet in sign in page, as timerUpdater has not been created yet.
    this.wakeUpLibMgr = () => {
        if (this.timerHandleLibMgr) {
            clearTimeout(this.timerHandleLibMgr);
            this.timerHandleLibMgr = setTimeout(this.oneSecondLoopLibMgr, 1);
        }
    };

    this.canProcessLibMgr = () => {
        if (window.libMgrInProgress) {
            this.logger.info(`window.libMgrInProgress [${window.libMgrInProgress}], returning...`);
            return false;
        }
        if (window.backgroundLibsPending.length === 0) {
            return false;
        }
        const urlNext = window.backgroundLibsPending[0];
        const hasError = window.libDownloaded[urlNext].error;
        // When modal is open, we don't automatically retry when there is an error
        const isModalOpen1 = $('#modal-loading-libmgr').hasClass('in') || $('#modal-loading-libmgr').hasClass('show');
        if (isModalOpen1 && hasError) {
            this.logger.info(`isModalOpen1 [${isModalOpen1}] hasError [${hasError}] resetting counters`);
            this.elapsedSec = 0;
            this.delaySecClamped = 0;
            this.retryCount = 0;
            return false;
        }
        if (this.elapsedSec < this.delaySecClamped) {
            return false;
        }
        return true;
    };

    this.processLibMgr = async () => {
        await retryDownloadBackgroundLibs(false);
    };

    this.clamp = (value, min, max) => {
        return Math.max(min, Math.min(value, max));
    };

    this.retryCount = 0;
    this.elapsedSec = 0;
    this.delaySecClamped = 0;
    this.timerHandleLibMgr = null;
    this.loopCounter1MinLibMgr = 0;
    this.oneSecondLoopLibMgr = async () => {
        try {
            clearTimeout(this.timerHandleLibMgr);
            this.timerHandleLibMgr = null;

            if (this.shutdown) {
                this.logger.log('AuthManager exiting oneSecondLoopLibMgr');
                return;
            }

            if (this.loopCounter1MinLibMgr % 30 === 0) {
                this.logger.info(`loopCounter1MinLibMgr [${this.loopCounter1MinLibMgr}]`);
                this.loopCounter1MinLibMgr = 0;
            }
            this.elapsedSec += 1;
            if (this.delaySecClamped > 0) {
                this.logger.info(`this.elapsedSec [${this.elapsedSec}] this.delaySecClamped [${this.delaySecClamped}]`);
            }
            const okToProceed = this.canProcessLibMgr();
            if (okToProceed) {
                // These 2 can be before or after await
                this.elapsedSec = 0;
                this.delaySecClamped = 0;
                await this.processLibMgr();
                // after await so that error will not reset count!
                this.retryCount = 0;
            }
            this.loopCounter1MinLibMgr += 1;
            this.timerHandleLibMgr = setTimeout(this.oneSecondLoopLibMgr, 1000);
        } catch (err) {
            this.logger.log(`AuthManager Failed to run 1s loop libmgr: ${err.stack || err.message}`);
            const baseDelayMs = 1000;
            // UtilsCommon not available yet on startup, use simplified randomness
            // const jitterMs = UtilsCommon.getRandomIntInclusiveRng(0, 50, UtilsCommon.rngTmp);
            // Make jitter between 400 and 600 ms, so it can round down/up to the second better
            const jitterMs = Math.floor(Math.random() * (600 - 400 + 1)) + 400;
            const delayMs = baseDelayMs * 2 ** this.retryCount + jitterMs;
            const delayMsClamped = Math.min(delayMs, 15000);
            this.delaySecClamped = Math.round(delayMsClamped / 1000);
            this.elapsedSec = 0;
            this.logger.info(`this.retryCount [${this.retryCount}] jitterMs [${jitterMs}] delayMs [${delayMs}] delayMsClamped [${delayMsClamped}][${this.delaySecClamped}]`);
            this.retryCount += 1;
            this.timerHandleLibMgr = setTimeout(this.oneSecondLoopLibMgr, 1000);
        }
    };
}

module.exports = AuthManager;
